<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-row>
          <v-col
            cols="6"
            sm="6"
            md="4"
            lg="3"
            xl="3"
          >
            Codice Interno Ricavi
            <v-text-field v-model="internalCode" flat rounded filled disabled
              prepend-icon="mdi-code-tags" hide-details="auto"
              @input="updateObject('internalCode', $event)"></v-text-field>
          </v-col>
          <v-col
          cols="6"
          sm="6"
          md="4"
          lg="2"
          xl="2"
          >
            Progressivo
            <v-text-field v-model="progressive" flat rounded filled disabled hide-details="auto"
              @input="updateObject('code', $event)"></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
            xl="3"
          >
            Numero Documento
            <v-text-field v-model="documentNumber" flat rounded filled label="Numero documento"
              hide-details="auto" @input="updateObject('documentNumber', $event)" :rules="[presenceRule]" :error="!documentNumber"></v-text-field>
          </v-col>
          <v-col
            cols="10"
            sm="10"
            md="10"
            lg="3"
            xl="3"
          >
            <p class="text-center ma-0">Mittente</p>
            <div class="d-flex ml-2">
              <v-btn-toggle
                v-model="otherCashSubject"
                rounded
                class="pa-1"
                mandatory
              >
                <v-btn>
                  <v-icon>mdi-account</v-icon>
                </v-btn>
                <v-btn>
                  <v-icon>mdi-domain</v-icon>
                </v-btn>
              </v-btn-toggle>
              <CustomersAutocomplete
                v-if="!otherCashSubject"
                style="min-width: 85%"
                class="pr-5" 
                v-model="customerSelected" 
                return-object
                :error="!customerId" 
                @input="handleCustomerChange" 
                append-icon="mdi-account"
              ></CustomersAutocomplete>
              <SuppliersAutocomplete
                v-else-if="!!otherCashSubject"
                style="min-width: 85%"
                class="pr-5" 
                v-model="supplierSelected" 
                return-object
                :error="!supplierId" 
                @input="handleSupplierChange" 
                append-icon="mdi-domain"
              ></SuppliersAutocomplete> 
            </div>  
          </v-col> 
          <v-col
            cols="1"
            sm="1"
            md="1"
            lg="1"
            xl="1"
            class="d-flex align-center justify-center"
            :class="{
              'ml-3': $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            }"
          >
            <v-switch color="red" v-model="ghost" :readonly="variant == 'new' ? false : true"
              @change="updateObject('ghost', $event)"></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                Note / Descrizione Movimento
                <v-textarea v-model="description" flat rounded filled hide-details="auto"
                  @input="updateObject('description', $event)" :rules="[presenceRule]" :error="!description" rows="2"></v-textarea>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="4"
                lg="4"
                xl="4"
              >
                Data Emissione
                <div class="pr-5">
                  <DatePickerCommon filled flat label="Data Emissione" v-model="emittedAt" :rules="[presenceRule]"
                    :error="!emittedAt" :disabled="variant == 'new' ? false : true"
                    @input="updateObject('emittedAt', $event)"></DatePickerCommon>
                </div>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="4"
                lg="4"
                xl="4"
              >
                Data Scadenza
                <div class="pr-5">
                  <DatePickerCommon filled flat label="Data Scadenza" v-model="expirationDate" :rules="[presenceRule]"
                    :error="!emittedAt" :disabled="variant == 'new' ? false : true"
                    @input="updateObject('expirationDate', $event)"></DatePickerCommon>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xl="4"
              >
                Operatore
                <OperatorsAutocomplete v-model="operatorId" :multiple="false"
                  :rules="[presenceRule]" :error="!operatorId" @input="updateObject('operatorId', $event)"
                  rounded
                  flat
                  dense
                ></OperatorsAutocomplete>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="4"
                lg="4"
                xl="4"
              >
                Modalità Di Pagamento
                <v-select v-model="paymentTermId" :items="paymentTermsItems" item-value="id" item-text="description"
                  label="Modalità Di Pagamento" filled rounded flat dense
                  :rules="[presenceRule]" :error="!paymentTermId" hide-details="auto" prepend-icon="mdi-contactless-payment"
                  @change="updateObject('paymentTermId', $event)"></v-select>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="4"
                lg="4"
                xl="4"
              >
                Tipo Natura Esenzione IVA
                <v-select v-model="paymentTypeNatureExemptionId" :items="paymentTypeNatureExemptionItems" item-value="id"
                  item-text="description" label="Tipo Natura Esenzione IVA" filled rounded flat
                  dense hide-details="auto" prepend-icon="mdi-contactless-payment"
                  @change="updateObject('paymentTypeNatureExemptionId', $event)"></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Aliquota
            <TaxesListSelector v-model="otherCashAmounts[0].vatPercentage" :rules="[presenceRule]" :input="updateAmounts(0)" :readonly="variant == 'new' ? false : true"></TaxesListSelector>
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Imponibile
            <v-text-field v-model="otherCashAmounts[0].taxable" dense filled label="Imponibile" hide-details="auto"
              :rules="[greaterThan0]" rounded flat type="number"
              :input="updateAmounts(0)" :readonly="variant == 'new' ? false : true"></v-text-field>
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Importo
            <v-text-field :value="otherCashAmounts[0].amount" :rules="[greaterThan0]" dense filled label="Importo"
              type="number" hide-details="auto" rounded flat readonly></v-text-field>
            <!--
            :input="updateAdditionalAmount(0)"
            -->
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Importo Iva
            <v-text-field :value="otherCashAmounts[0].vatAmount" dense filled label="Importo Iva" readonly
              hide-details="auto" rounded flat></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="8"
            md="8"
            lg="4"
            xl="4"
          >
            Tag Abbinato
            <TagsAutocomplete :multiple="false" v-model="otherCashAmounts[0].tags" dense return-object
              @input="updateAmounts(0)"  :rules="[presenceRule]"  :error="!otherCashAmounts[0].tags"
              :filters="{ typeCost: false }"
            >
            </TagsAutocomplete>
          </v-col>
        </v-row>

        <!-- Per più aliquote -->
        <v-row v-for="(n) in nAdditionalTaxes" :key="n">
          <v-col 
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Aliquota
            <TaxesListSelector v-model="otherCashAmounts[n].vatPercentage" :rules="[greaterThan0]" :change="updateAmounts(n)" :readonly="variant == 'new' ? false : true"></TaxesListSelector>
          </v-col>
          <v-col 
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Imponibile
            <v-text-field v-model="otherCashAmounts[n].taxable" dense filled label="Imponibile" hide-details="auto"
              :rules="[greaterThan0]" rounded flat type="number"
              :change="updateAmounts(n)" :readonly="variant == 'new' ? false : true"></v-text-field>
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Importo
            <v-text-field :value="otherCashAmounts[n].amount" :rules="[greaterThan0]" dense filled label="Importo"
              type="number" hide-details="auto" rounded readonly flat></v-text-field>
            <!--
              :input="updateAdditionalAmount(n)"
            -->
          </v-col>
          <v-col
            cols="6"
            sm="4"
            md="2"
            lg="2"
            xl="2"
          >
            Importo Iva
            <v-text-field :value="otherCashAmounts[n].vatAmount" dense filled label="Importo Iva" readonly
              type="number" hide-details="auto" rounded flat></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="8"
            md="8"
            lg="4"
            xl="4"
          >
            Tag Abbinato
            <TagsAutocomplete :multiple="false" v-model="otherCashAmounts[n].tags" dense return-object
              @input="updateAmounts(n)"  :rules="[presenceRule]"  :error="!otherCashAmounts[n].tags"
              :filters="{ typeCost: false }"
            >
            </TagsAutocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center mb-2">
            <v-btn color="primary" outlined class="px-10 mr-2" @click="addRow()" :disabled="variant == 'new' ? false : true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn color="error" outlined class="px-10 ml-2" @click="removeRow()" :disabled="nAdditionalTaxes < 1 || variant != 'new'">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from "vue";
import otherCashForm from "@/services/otherCashes/otherCashes.form.js";
import SuppliersAutocomplete from "@/components/common/SuppliersAutocomplete.vue";
import DatePickerCommon from "@/components/common/DatePickerCommon.vue";
import TagsAutocomplete from "@/components/common/TagsAutocomplete.vue";
import OperatorsAutocomplete from "@/components/common/OperatorsAutocomplete.vue";
import paymentTermService from "@/services/paymentTerms/paymentTerms.service.js";
import paymentTypeNatureExemptionService from "@/services/paymentTerms/paymentTypeNatureExemptions.service.js";
import TaxesListSelector from "@/components/areas/payments/taxes/TaxesListSelector.vue"
import CustomersAutocomplete from "@/components/common/CustomersAutocomplete.vue";

export default {
  name: "GeneralFormTab",
  components: {
    SuppliersAutocomplete,
    DatePickerCommon,
    TagsAutocomplete,
    OperatorsAutocomplete,
    CustomersAutocomplete,
    TaxesListSelector
  },
  data: function () {
    let today = new Date();
    return {
      amount: 0,
      internalCode: 'ARN' + today.getFullYear() + '-00001',
      progressive: 1,
      documentNumber: undefined,
      supplierId: undefined,
      customerId: undefined,
      description: undefined,
      emittedAt: undefined,
      expirationDate: undefined,
      paymentTermId: undefined,
      paymentTypeNatureExemptionId: undefined,
      operatorId: undefined,
      ghost: false,

      otherCashAmounts: [{}],
      nAdditionalTaxes: 0,

      otherCashSubject: 0,
      supplierSelected: undefined,
      customerSelected: undefined,
      paymentTermsItems: [],
      paymentTypeNatureExemptionItems: [],
      once: true,

      formValid: false,
      max255CharRule: (v) => {
        if (v) {
          return v.length <= 255 || "Al massimo 255 caratteri";
        } else {
          return true;
        }
      },
      presenceRule: (v) => {
        return !!v || v === 0 || "Il campo è obbligatorio";
      },
      greaterThan0: (v) => {
        return v > 0 || "Il valore deve essere maggiore di 0";
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    variant: {
      type: String,
      default: "new",
    },
  },
  mounted: function () {
    this.handleObjectChanges(otherCashForm.otherCash);
    this.fetchPaymentTerms();

    var self = this;
    otherCashForm.on("update", function (data) {
      self.handleObjectChanges(data.otherCash);
    });
  },
  methods: {
    fields() {
      return [
        'amount',
        'internalCode',
        'progressive',
        'documentNumber',
        'supplierId',
        'customerId',
        'description',
        'emittedAt',
        'expirationDate',
        'paymentTermId',
        'paymentTypeNatureExemptionId',
        'operatorId',
        'ghost',
        'otherCashAmounts'
      ]
    },
    handleObjectChanges(otherCash) {
      const fields = this.fields()
      const newValKeys = Object.keys(otherCash)

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if (field == 'supplierId' && otherCash[field] != this[field]) {
          this[field] = otherCash[field]
          if(!!otherCash.supplier){
            this.supplierSelected = otherCash.supplier
            this.otherCashSubject = 1
          }
        }
        else if (field == 'customerId' && otherCash[field] != this[field]) {
          this[field] = otherCash[field]
          if(!!otherCash.customer){
            this.customerSelected = otherCash.customer
            this.otherCashSubject = 0
          }
        }
        else if ((field == 'emittedAt' || field == 'expirationDate') && otherCash[field] != this[field]) {
          this[field] = otherCash[field] ? new Date(otherCash[field]) : undefined
        }
        else if(field == 'otherCashAmounts' && otherCash[field] != this[field]) {
          if (!!otherCash[field]) {
            for (let i = 0; i < otherCash[field].length; i++) {
              for (let j = 0; j < otherCash[field][i].tags.length; j++) {
                if (this.once) {
                  otherCash[field][i].tags[j].description = otherCash[field][i].tags[j].description + " - " + otherCash[field][i].tags[j].tagCategoryName
                  this.once = false
                }
              }            
            }
            this.nAdditionalTaxes = otherCash[field].length - 1
            this[field] = otherCash[field]
          }
          else{
            this.nAdditionalTaxes = 0
            this[field] = [{}]
          }

        }
        else if (newValKeys.includes(field) && otherCash[field] != this[field]) {
          this[field] = otherCash[field]
        }
      }
    },
    updateObject(key, value) {
      if(key == 'emittedAt'){
        let today = new Date();
        let newDate = new Date(value);
        today.setDate(newDate.getDate());
        today.setMonth(newDate.getMonth());
        today.setFullYear(newDate.getFullYear());
        if(this.expirationDate != today)
          this.expirationDate = today;
        value = today;
      }

      otherCashForm.updateField(key, value);
    },
    handleSupplierChange(supplier) {
      this.supplierSelected = supplier;
      this.updateObject("supplierId", supplier.id);
      this.customerId = undefined;
      this.updateObject("customerId", undefined);
    },
    handleCustomerChange(customer) {
      this.customerSelected = customer;
      this.updateObject("customerId", customer.id);
      this.supplierId = undefined;
      this.updateObject("supplierId", undefined);
    },
    fetchPaymentTerms() {
      new Promise(async (resolve, reject) => {
        let resultPaymentTermsItems = await paymentTermService.list();
        this.paymentTermsItems = [...resultPaymentTermsItems.rows];

        let resultPaymentTypeNatureExemptionItems = await paymentTypeNatureExemptionService.list();
        this.paymentTypeNatureExemptionItems = [...resultPaymentTypeNatureExemptionItems.rows];

        resolve();
      });
    },
    updateAmounts(index) {
      if (!!this.otherCashAmounts[index].taxable && this.otherCashAmounts[index].vatPercentage != undefined && this.otherCashAmounts[index].vatPercentage != null) {
        let percentage = Number(this.otherCashAmounts[index].vatPercentage)
        let taxable = Number(this.otherCashAmounts[index].taxable)

        let amount = (taxable + (taxable * percentage / 100)).toFixed(2)
        let vatAmount = (taxable * percentage / 100).toFixed(2)

        if(this.otherCashAmounts[index].amount == amount && this.otherCashAmounts[index].vatAmount == vatAmount)
          return

        this.otherCashAmounts[index].amount = amount
        this.otherCashAmounts[index].vatAmount = vatAmount

        this.updateObject('otherCashAmounts', this.otherCashAmounts)
      }
    },
    addRow(){
      this.nAdditionalTaxes++;

      this.otherCashAmounts.push({})
    },
    removeRow(){
      this.otherCashAmounts.pop()
      
      this.updateObject('otherCashAmounts', this.otherCashAmounts)
      this.nAdditionalTaxes--;
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit("update:valid", newVal);
      if (this.bus) {
        this.bus.$emit("update:valid", newVal);
      }
      otherCashForm.setValid(newVal);
    },
  },
};
</script>