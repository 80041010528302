import eventsHandler from '@/services/common/eventsHandler.js'

class otherDialogForm extends eventsHandler {
  constructor() {
    super()
    this._otherCashes = {}
    this._valid = false
  }

  get otherCash() {
    const otherCash = {
      ...this._otherCashes
    }
    return otherCash
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._otherCashes.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetOtherCashes() {
    this._otherCashes = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {otherCash: {}})
  }

  updateField(key, value) {
    this._otherCashes[key] = value
    this._executeCallbacksOf('update', {otherCash: this._otherCashes})
    this._executeCallbacksOf('update-' + key, {key: this._otherCashes[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(otherCash) {
    this._otherCashes = otherCash
    this._executeCallbacksOf('update', {otherCash: this._otherCashes})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new otherDialogForm()